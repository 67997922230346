import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes: [
    // {
    //   path: '/pages',
    //   component: () => import('@/views/pages/Index'),
    //   children: [
    //     {
    //       name: 'Login',
    //       path: 'login',
    //       component: () => import('@/views/pages/Login'),
    //     },
    //     {
    //       name: 'Register',
    //       path: 'register',
    //       component: () => import('@/views/pages/Register'),
    //     },
    //   ],
    // },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Search Products',
          path: '',
          component: () => import('@/views/dashboard/pages/ProductsSearch'),
        },
        // {
        //   name: 'Appointment',
        //   path: 'calendar',
        //   component: () => import('@/views/dashboard/pages/Appointment'),
        // },
        // {
        //   name: 'My Profile',
        //   path: 'profile',
        //   component: () => import('@/views/dashboard/pages/UserProfile'),
        // },
      ],
    },
    {
      path: '/products/:product_id',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Product',
          path: '',
          component: () => import('@/views/dashboard/pages/Product'),
        },
      ],
    },
    {
      path: '/products-list',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Products List',
          path: '',
          component: () => import('@/views/dashboard/pages/ProductsList'),
        },
      ],
    },
    {
      path: '/products-list/export/:list_id/:list_key',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Products List Export',
          path: '',
          component: () => import('@/views/dashboard/pages/ProductsListExport'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})
