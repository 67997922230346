// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import LoadScript from 'vue-plugin-load-script'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import titleMixin from './mixins/titleMixin'
import VueQrcodeReader from 'vue-qrcode-reader'

Amplify.configure(awsconfig)

Vue.config.productionTip = false

Vue.use(LoadScript)
Vue.use(VueQrcodeReader)

Vue.filter('superscriptfixmB2', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.replace('mB2', 'm²')
})

Vue.mixin(titleMixin)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
